export const menuItems = [
    {
        id: 1,
        label: "Navigation",
        isTitle: true
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/'
    },
    {
        id: 3,
        label: "Orders",
        icon: "ri-sort-desc",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 4,
                label: 'Draft',
                link: '/orders?status=draft'
            },
            {
                id: 4,
                label: 'Active',
                link: '/orders?status=active'
            },
            {
                id: 5,
                label: 'Delivered',
                link: '/orders?status=delivered'
            },
            {
                id: 6,
                label: 'Completed',
                link: '/orders?status=completed'
            },
        ]
    },
    {
        id: 10,
        label: "Products",
        icon: "ri-store-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 12,
                label: 'View List',
                link: '/products'
            },
        ]
    },
    {
        id: 14,
        label: "Users",
        icon: "ri-shield-user-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 15,
                label: 'Creat New',
                link: '/users/create'
            },
            {
                id: 16,
                label: 'View List',
                link: '/users'
            }
        ]
    },
    {
        id: 23,
        label: "Payments",
        icon: "ri-money-dollar-box-line",
        link: '/payments'
    },
    {
        id: 25,
        label: "Settings",
        icon: "ri-settings-2-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 26,
                label: 'Coupons',
                link: '/settings/coupons'
            }
        ]
    },
    {
        id: 28,
        label: "Marketing",
        icon: "ri-mail-send-line",
        link: '/marketing'
    },
];
