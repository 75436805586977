
<template>
<!-- Begin page -->
<div id="wrapper">
    <Sidebar :type="layout.leftSidebarType" :width="layout.layoutWidth" :size="layout.leftSidebarSize" :menu="layout.menuPosition" :topbar="layout.topbar" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page" :class="{'content-page-fixed': layout.contentPageFixed}">
        <div class="content">
            <Topbar />
            <!-- Start Content-->
            <div class="container-fluid px-0 pt-3">
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
        <Footer />
    </div>
</div>
</template>

<script>
import Sidebar from '@/layouts/components/sidebar/LeftSidebar.vue'
import Topbar from '@/layouts/components/topbar/Topbar.vue'
import Footer from '@/layouts/components/Footer.vue'
/**
 * Vertical layout
 */
export default {
    name: 'vertical',
    components: {
      Sidebar,
      Topbar,
      Footer
    },
    data() {
        return {
            isMenuCondensed: false,
        }
    },
    computed: {
        layout()  { return this.$store.state.layout },
    },
    methods: {
        toggleRightSidebar() {
            document.body.classList.toggle("right-bar-enabled");
        },
        hideRightSidebar() {
            document.body.classList.remove("right-bar-enabled");
        },
        toggleMenu() {
            this.isMenuCondensed = !this.isMenuCondensed
            if (this.isMenuCondensed)
                document.body.setAttribute('data-sidebar-size', 'condensed');
            else
                document.body.removeAttribute('data-sidebar-size', 'condensed');

            if (window.screen.width >= 992) {
                this.$router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove("sidebar-enable");
                });
            } else {
                document.body.setAttribute('data-sidebar-size', 'default');
                document.body.classList.toggle("sidebar-enable");
                this.$router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove("sidebar-enable");
                });
            }
        },
    },
    mounted() {
        document.body.removeAttribute("data-layout-mode");
        document.body.removeAttribute("data-sidebar-showuser");
    },
}
</script>

<style type="text/css">
.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.content-page.content-page-fixed{
    position: fixed;
    right:0;
    left:0;
}
</style>
