
<template>
<div>
    <div v-if="layout.loader" class="busy-container position-relative">
      <div class="app-busy-mood text-center d-flex justify-content-center align-items-center">
          <img src="/images/loading.gif" width="60">
      </div>
    </div>
    <!-- Begin page -->
    <Vertical v-if="layout.layoutType === 'vertical'" :layout="layout.layoutType">
      <router-view />
    </Vertical>
</div>
</template>

<script>

import Vertical from '@/layouts/components/vertical/vertical.vue'
import themeConfig    from '@/themeConfig.js'

export default {
  components: {
      Vertical,
  },
  data() {
    return {

    }
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
  created() {
    if(!this.$store.state.orderList.orders.length){
      this.$store.dispatch("orderList/fetchOrders")
    }
  },
  mounted() {
      if (this.$route.query.layout) {
          this.$store.dispatch('layout/changeLayoutType', {
              layoutType: this.$route.query.layout
          })
      }
  }
}
</script>

<style type="text/css">
  .app-busy-mood{
    background: rgb(237 229 231);
    position: fixed;
    top: 0;
    z-index: 99999;
    opacity:0.4;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .font-sm{
    font-size:13px;
  }
  .cursor-pointer{
    cursor: pointer !important;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #f2f3f5;
  }

</style>
