<template>
  <b-nav-item-dropdown right class="notification-list topbar-dropdown" menu-class="profile-dropdown" toggle-class="p-0">
    <template slot="button-content" class="nav-link dropdown-toggle">
        <div class="nav-user mr-0">
            <img :src="$store.state.domain+'/'+activeUser.image" alt="user-image" class="rounded-circle" />
            <span class="pro-user-name ml-1">
                {{activeUser.name}}
                <i class="mdi mdi-chevron-down"></i>
            </span>
        </div>
    </template>

    <b-dropdown-item href="#" @click.prevent="$router.push('/account?tab=profile').catch(()=>{})">
        <i class="ri-account-circle-line"></i>
        <span>Profile</span>
    </b-dropdown-item>
     <b-dropdown-item href="#" @click.prevent="$router.push('/account?tab=settings').catch(()=>{})">
        <i class="ri-user-settings-line"></i>
        <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item href="#" @click.prevent="$router.push('/account?tab=password').catch(()=>{})">
        <i class="ri-lock-password-line"></i>
        <span>Password</span>
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <a class="dropdown-item" @click.prevent="logout" href="jvascript: void(0);">
        <i class="fe-log-out mr-1"></i>
        <span> Logout </span>
    </a>
</b-nav-item-dropdown>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  computed: {
    activeUser() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("changeLoaderValue",true)
      this.$store.dispatch("logoutUser")
      this.$http.defaults.headers.Authorization = "";
      localStorage.removeItem(this.$store.state.localUserKey);
      this.$router.push({path: '/login'})
    },
  }
}
</script>
